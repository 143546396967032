<template>
  <div>
    <div class="main">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped>
</style>




